<script>
import { VIconPicker } from 'v-icon-picker'

/**
 * Displays a doughnut with given values
 * @displayName PieChart
 */
export default {
  extends: VIconPicker,
  props: {
    /**
     * Contains the data to display in the doughnut
     */
    chartData: {
      type: Object,
      default(){
        return {}
      }
    },
  },
  data () {
    return {

    }
  },
  mounted () {
  }
}
</script>
